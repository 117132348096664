<template>
    <v-row class="pt-5">
        <top-nav title="Evidencias"></top-nav>
        <v-row>
            <v-col cols="12">
                <v-text-field v-model="q" class="rounded-xl mb-3 mx-3 mt-2" flat solo background-color="grey lighten-3" label="Buscar" dense hide-details prepend-inner-icon="mdi-magnify" clearable></v-text-field>
                <div v-if="q && filteredCustomerResources.length === 0" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
                    <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                    <span>No se encontraron resultados para <strong>"{{ q }}"</strong></span>
                </div>
                <div v-else-if="q" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
                    <span>Mostrando {{ filteredCustomerResources.length }} {{ resultText }} para <strong>"{{ q }}"</strong></span>
                </div>
                <v-list two-line class="mt-5" v-show="!loading">
                    <template v-for="(cR, i) in filteredCustomerResources">
                        <v-list-item @click="hasVideo(cR) ? showCustomerResource(cR) : null" :key="cR.id">
                            <v-list-item-content>
                                <v-list-item-title>{{ getProductName(cR.productId) }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <div>{{ getResourceName(cR.productId, cR.resourceId) }}</div>
                                    <div>{{ getCustomerName(cR.customer) }}</div>
                                    <div>{{ cR.dateCreated }}</div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="i < filteredCustomerResources.length - 1" :key="cR.id + '-divider'"></v-divider>
                    </template>
                </v-list>
            </v-col>
        </v-row>
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card class="petu-green-bg" dark>
                <v-card-text> 
                    <span>Cargando evidencias</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <customer-resource v-model="showResource" :customerResource="selectedCustomerResource"></customer-resource>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TopNav from '@/components/admin/TopNav'
import CustomerResource from '@/components/admin/CustomerResource'

import { PetuCustomer } from '@/classes/customer';
import { PetuProduct } from '@/classes/product';


export default {
    name: 'EvidenceList',
    data: () => ({ 
        q: '',
        loading: true,
        products: [],
        customers: [],
        showResource: false,
        selectedCustomerResource: {},
    }),
    components: { TopNav, CustomerResource },
    computed: {
        ...mapGetters({ user: 'auth/user', customerResources: 'customerResource/customerResources' }),
        filteredCustomerResources() {
            let q = this.q?.toLowerCase() || null
            return !this.q 
                ? this.customerResources.sort(this.compare)
                : this.customerResources.filter((cR) => {
                    return this.getCustomerName(cR.customer).toLowerCase().includes(q)
                    || this.getResourceName(cR.productId, cR.resourceId).toLowerCase().includes(q)
                    || this.getProductName(cR.productId).toLowerCase().includes(q)
                    || cR.createdAt.toLowerCase().includes(q)
                }).sort(this.compare);
        },
        resultText() {
            return this.filteredCustomerResources.length === 1 ? 'resultado' : 'resultados'
        },
    },
    async mounted() {
        await this.getCustomerResources()
        await this.getProducts()
        // await this.getCustomers()
        this.loading = false
    },
    methods: {
        ...mapActions({ 
            getCustomerResources: 'customerResource/getCustomerResources' 
        }),
        compare(a, b) {
            return a.createdAt > b.createdAt ? - 1 : Number(a.createdAt < b.createdAt)
        },
        async getProducts() {
            const products = [...new Set(this.customerResources.map((r) => r.productId))]
            
            for( let id of products ) {
                let product = new PetuProduct();
                await product.load(id);
                await product.loadResources();

                this.products.push(product.data);
            }
        },
        getResourceName(productID, resourceID) {
            const p = this.products.find((p) => p.id === productID);
            
            if( !p ) 
                return 'PRODUCT NOT FOUND';

            const r = p.resources.find((r) => r.id === resourceID);

            return r ? r.name : 'RESOURCE NOT FOUND'
        },
        getProductName(id) { 
            const p = this.products.find((p) => p.id === id)
            return p ? p.name : ''
        },
        getCustomerName(customer) { 
            return customer ? `${customer.firstName} ${customer.lastName}` : 'CUSTOMER NOT FOUND'
        },
        hasVideo(r) {
            return r.src.bucket && r.src.key && r.src.region && r.src.identityId;
        },
        showCustomerResource(r) {
            if( !this.hasVideo(r) ) 
                return;
            
            this.selectedCustomerResource = {
                ...r,
                resource: r.src,
                resourceName: this.getResourceName(r.productId, r.resourceId),
                productName: this.getProductName(r.productId),
                customerName: this.getCustomerName(r.customer),
            };

            this.showResource = true;
        }
    },
}
</script>
