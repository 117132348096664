<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay >
      <v-card tile>
        <v-toolbar dark class="petu-green-bg" dense flat>
            <v-toolbar-title>{{ customerResource.resourceName }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn icon dark @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <video height="240" style="width:100%" controls ref="video">
            <source ref="videoSource" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <v-card-title class="px-4">{{ customerResource.customerName }}</v-card-title>
        <v-card-subtitle class="px-4">
            <div>{{ customerResource.productName }}</div>
            <div>Sometido {{ customerResource.dateCreated }}</div>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
</template>
<script>

import { Storage } from 'aws-amplify'
import { _st } from '@/softech'

export default {
    name: 'CustomerResource',
    props: {
        value: { type: Boolean, default: false },
        customerResource: { type: Object, default() { return {} } },
    },
    data() {
        return {
            dialog: this.value,
            uploading: false,
        }
    },
    methods: {
        async loadResource() {
            if (this.customerResource) {
                let src = await Storage.get(this.customerResource.resource.key, { level: "protected", expires: 180, identityId: this.customerResource.resource.identityId });

                if( _st.isNU( this.$refs.videoSource ) ) {
                    setTimeout( () => {
                        this.$refs.videoSource.src = src;
                        this.$refs.video.load();
                    }, 500 );
                    return;
                }

                this.$refs.videoSource.src = src;
                this.$refs.video.load();
                return;
            }
        },
        clearResource() {
            if (_st.isNU( this.$refs.video)) return
            this.$refs.video.pause()
            this.$refs.videoSource.removeAttribute('src')
            this.$refs.video.load()
        }
    },
    watch: {
        async dialog(v) { 
            if (v) await this.loadResource()
            else    this.clearResource()

            this.$emit('input', v)
        },
        value(v) {
            this.dialog = v
        }
    },
    beforeDestroy() {
        this.clearResource()
    }
}
</script>